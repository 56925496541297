<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-3">
        <div class="card" data-aos="fade-right">
          <div class="card-body">
            <h5 class="card-title mb-4">Ваш скин</h5>
            <div class="d-flex flex-wrap gap-2 justify-content-center p-0">
              <canvas ref="skin"></canvas>
            </div>

            <!-- Alert -->
            <div
                v-if="preview"
                class="alert alert-success alert-dismissible alert-solid alert-label-icon fade show mt-2 mb-0"
                role="alert">
              <i class="ri-user-smile-line label-icon"></i><strong>Предпросмотр скина</strong>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div v-if="preview" class="card" data-aos="fade-right" data-aos-delay="100">
          <div class="card-header">
            <h4 class="card-title mb-0">Сохранение скина</h4>
          </div>
          <div class="card-body">
            <p class="text-muted">
              Нажмите <strong>сохранить</strong>, чтобы изменения вступили в силу.
              Нажмите <strong>отмена</strong> чтобы вернуть все как было.
            </p>

            <div class="d-flex mt-3">
              <button class="btn btn-label btn-success" @click="saveSkin">
                <i class="ri-file-upload-line align-bottom label-icon"></i> Сохранить
              </button>

              <button class="btn btn-danger btn-label ms-2" @click="reloadSkin(); preview = null;">
                <i class="ri-delete-bin-line align-bottom label-icon"></i> Отмена
              </button>
            </div>
          </div>
        </div>

        <div v-else-if="hasPermission('profile.change.skin')" class="card" data-aos="fade-right" data-aos-delay="100">
          <div class="card-header">
            <h4 class="card-title mb-0">Загрузить скин</h4>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Можете перенести в файл в зону ниже или выбрать его с помощью файлового менеджера
            </p>

            <!-- File Input Sizing Default -->
            <div>
              <input ref="skinInput"
                     class="form-control"
                     id="formFileSm"
                     type="file"
                     accept=".png"
                     :class="{
                      'is-invalid': errors.skin,
                    }"
              >

              <div
                  v-for="(item, index) in errors.skin"
                  :key="index"
                  class="invalid-feedback"
              >
                <span v-if="item"> {{ item }} </span>
              </div>
            </div>

            <div class="d-flex mt-3">
              <button class="btn btn-label btn-primary" @click="updateSkin">
                <i class="ri-file-upload-line label-icon align-bottom"></i> Изменить
              </button>
            </div>
          </div>
          <!-- end card body -->
        </div>

        <div v-else class="card" data-aos="fade-right" data-aos-delay="100">
          <div class="card-header">
            <h4 class="card-title mb-0">Загрузить скин</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <p class="text-muted">
              Загрузка скина отключена для этого аккаунта
            </p>
          </div>
        </div>
        <!-- end card -->
      </div>


      <div class="col-xxl-9">
        <div class="card" data-aos="fade-left">
          <div class="card-header">
            <h4 class="card-title mb-0">История скинов</h4>
          </div>

          <!-- Border spinner -->
          <div v-if="!storeSkinsLoaded"
               class="spinner-border skins-store-preloader text-primary" role="status"
          >
          </div>

          <div v-else-if="skins?.length < 1" class="pt-5 pb-2 text-center">
            Тут пусто...
          </div>

          <div ref="storeSkins"
               :class="{ disabled: !storeSkinsLoaded }"
               class="card-body skins-store d-flex flex-wrap justify-content-center"
          >
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import validate from "@/helpers/validate";
import { setSkin, getSavedSkins, deleteSavedSkin, setSavedSkin } from "@/helpers/home/look";
import { hasPermission } from "@/helpers/permissions";
const skinview3d = require("@/assets/js/skinview3d.bundle");

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Скин",
      items: [
        {
          text: "Аккаунт",
          href: "/",
        },
        {
          text: "Мой профиль",
          href: "/",
        },
        {
          text: "Скин",
          active: true,
        },
      ],
      preview: null,
      storeSkinsLoaded: false,
      errors: '',
      skins: [],
    };
  },
  computed: {
    user: function() {
      return this.$store.getters['auth/user'];
    }
  },
  methods: {
    hasPermission,
    async updateSkin() {
      const file = this.$refs.skinInput.files[0];
      const img = new Image();

      try {
        img.src = URL.createObjectURL(file);
        await img.decode();
        this.errors = {};
        this.errors.skin = validate.skin(img);
      } catch {
        this.errors.skin = ['Неверный формат файла'];
        return;
      }

      if (this.errors.skin) {
        return;
      }

      await this.reloadSkin(img.src);
      this.preview = file;
    },
    async saveSkin() {
      await setSkin(this.preview);
      this.preview = false;
      await this.reloadSkinsStore();
    },
    async reloadSkinsStore() {
      this.storeSkinsLoaded = false;
      this.skins = await getSavedSkins(this.user.login);
      this.$refs.storeSkins.innerHTML = '';

      const skinViewer = new skinview3d.SkinViewer({
        width: 235,
        height: 300,
        renderPaused: true
      });

      skinViewer.camera.rotation.y = -0.6;
      skinViewer.camera.position.x = -24;
      skinViewer.camera.position.z = 35.0;

      for (const config of this.skins) {
        await skinViewer.loadSkin(config.skin);
        try {
          await skinViewer.loadCape(this.user.cape);
        } catch {
          // Cape not exist
        }
        skinViewer.render();
        const image = skinViewer.canvas.toDataURL();

        //create div
        const contain = document.createElement('div');
        contain.classList.add('skin-store-container');
        contain.setAttribute('data-aos', 'fade');

        // create img
        const imgElement = document.createElement("img");
        imgElement.src = image;
        imgElement.width = skinViewer.width;
        imgElement.height = skinViewer.height;

        contain.appendChild(imgElement);
        contain.innerHTML += `
            <div class="d-flex mt-3 mw-100 d-flex justify-content-center align-items-center">
              <button class="btn btn-primary" action-type="set" action-id="${config.id}">
                <i class="ri-file-upload-line align-bottom"></i> Установить
              </button>
              <button class="btn btn-danger ms-2" action-type="delete" action-id="${config.id}">
                <i class="ri-delete-bin-line align-bottom"></i> Удалить
              </button>
            </div>
        `;

        this.$refs.storeSkins.appendChild(contain);

        skinViewer.dispose();
      }

      this.storeSkinsLoaded = true;
    },
    async reloadSkin(skin = this.user.skin) {
      let skinViewer = new skinview3d.SkinViewer({
        canvas: this.$refs.skin,
        width: 300,
        height: 400,
        skin
      });

      // Change viewer size
      skinViewer.width = 300;
      skinViewer.height = 400;

      // Load a cape
      try {
        await skinViewer.loadCape(this.user.cape);
      } catch {
        // Cape not exist
      }

      // Rotate the player
      skinViewer.autoRotate = true;

      // Apply an animation
      skinViewer.animation = new skinview3d.WalkingAnimation();
    }
  },
  async mounted() {
    await this.reloadSkin();
    await this.reloadSkinsStore();

    this.$refs.storeSkins.addEventListener('click', async (event) => {
      const type = event.target.getAttribute('action-type');
      const id = event.target.getAttribute('action-id');

      if (type === 'delete') {
        this.storeSkinsLoaded = false;
        await deleteSavedSkin(id);
        await this.reloadSkinsStore();
      }

      if (type === 'set') {
        this.storeSkinsLoaded = false;
        await setSavedSkin(id);
        await this.reloadSkin();
        await this.reloadSkinsStore();
      }
    })
  }
}
</script>

<style>
.skin-store-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  border: 1px solid var(--vz-gray-300);
  margin: 10px;
}

.skins-store.disabled {
  display: none !important;
}

.skins-store-preloader {
  width: 3.5rem;
  height: 3.5rem;
  margin: 50px auto;
}
</style>